<template>
  <v-container fluid>
    <div class="contacts">
      <h1 class="pa-1 ma-1">Contacts</h1>
    </div>
    <div class="row pa-1 ma-1">
      <Editor
        mode="viewer"
        ref="editor"
        hint="Hint"
        :outline="false"
        v-model="contacts.body"
        />
    </div>
  </v-container>
</template>

<script>
import { Editor } from "vuetify-markdown-editor"
export default {
  name: 'Contacts',
  components: {
    Editor
  },
  data: function () {
    return {
        contacts: [
        ]
    }
  },

  beforeMount () {
    this.$axios.get(this.$api + '/contacts').then(function (res) {
      this.contacts = res.data
    }.bind(this))
  }
  
}
</script>